<template>
  <svg viewBox="0 0 10 10">
    <path
      d="M4.33398 4.3335V0.333496H5.66732V4.3335H9.66732V5.66683H5.66732V9.66683H4.33398V5.66683H0.333984V4.3335H4.33398Z"
    />
  </svg>
</template>

<script>
export default {
  name: "PlusSimpleIcon",
};
</script>
